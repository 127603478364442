import React from 'react'
import loadable from '@loadable/component'
import Layout from '../../components/Layout'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Img from "gatsby-image"

const Slider = loadable(() => import('../../components/Slider'));
const ConctactCall = loadable(() => import('../../components/ContactCall'));
const Typewriter = loadable(() => import('typewriter-effect'));


export default class Index extends React.Component {

  componentDidMount(){

      var lazyBackgrounds = [].slice.call(
        document.querySelectorAll(".lazy-background")
      );

      if (
        "IntersectionObserver" in window &&
        "IntersectionObserverEntry" in window &&
        "intersectionRatio" in window.IntersectionObserverEntry.prototype
      ) {
        let lazyBackgroundObserver = new IntersectionObserver(function (
          entries,
          observer
        ) {
          entries.forEach(function (entry) {
            if (entry.isIntersecting) {
              entry.target.classList.add("visible");
              if(entry.target.classList.contains("three")){
                countDown();
              }
              lazyBackgroundObserver.unobserve(entry.target);

            }
          });
        });
        lazyBackgrounds.forEach(function (lazyBackground) {
          lazyBackgroundObserver.observe(lazyBackground);
        });
      }
    ;
    document.getElementById('lang-switch').href = 'https://e-multicontent.pl/o-nas/'
  }
  render() {
    const { data } = this.props
    return (

  <>
  <Helmet titleTemplate="Find out who we are and how we work! | e-multicontent">
    <title>Find out who we are and how we work! | e-multicontent</title>
    <meta name="description" content={'We work in Polish on a daily basis, but we are familiar with other languages. Native speakers from all over the world work for us. ✔️'}/>
    <meta name='image' content={'https://e-multicontent.com/img/meta/onas-meta.jpg'} />
    <meta name='keywords' content={'copywriting, copywriters, marketing, copywriters agency, Wrocław, team of copywriters, work, business, content, content, translations, SEO, blog, texts, articles, graphics, video, websites, online stores, marketplace, e-commerce'} />
    <meta property="og:description" content={'We work in Polish on a daily basis, but we are familiar with other languages. Native speakers from all over the world work for us. ✔️'}/>
    <meta property='og:title' content={'Find out who we are and how we work! | e-multicontent.com'} />
    <meta property='og:image' content={'https://e-multicontent.com/img/meta/onas-meta.jpg'} />
    <meta property="og:url" content={'https://e-multicontent.com/about-us/'}/>
    <link rel="alternate" href="https://e-multicontent.pl/o-nas/" hrefLang="pl-pl" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta property="twitter:domain" content="e-multicontent.com" />
    <meta property="twitter:url" content="https://e-multicontent.com/about-us/" />
    <meta name="twitter:title" content="Find out who we are and how we work! | e-multicontent.com" />
    <meta name="twitter:description" content="We work in Polish on a daily basis, but we are familiar with other languages. Native speakers from all over the world work for us. ✔️" />
    <meta name="twitter:image" content="https://e-multicontent.com/img/apple-touch-icon.png" />

    <script type='application/ld+json'>
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://e-multicontent.com"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "About us",
        "item": "https://e-multicontent.com/about-us/"
      }]
    })}
    </script>
  </Helmet>
  <Layout>

      <nav className="breadcrumbs-nav section">
          <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600'}}><b>About us</b></span>
      </nav>
  <section className="section " style={{padding:'0px',margin:'0px',width:'100%'}}>


  <div
    id="o-nas"
    className="full-width-image-container margin-top-20"
    style={{
      backgroundPosition: `center right`,
      backgroundSize:'cover',
      transform: 'scale(1)',
      height:'509px',
      backgroundRepeat: 'no-repeat',
      paddingTop: '0px',

    }}
  >

    </div>


              <h1
                className="has-text-weight-bold is-size-1"
                style={{
                  color: '',
                  lineHeight: '1',
                  padding: '0.35em',
                  borderRadius:'10px',
                  marginTop: '0%',
                  display: 'block',
                  position: 'absolute',
                  left: '7%',
                  top: '200px',
                  textAlign: 'left',
                  backgroundColor:'rgba(255,255,255,0.7)',



                }}
              >
              About <b style={{color:'#36b3d2'}}>us</b>
              <br />
              </h1>
              <br />
              <h2
              className="mright"
              style={{
                color: 'rgb(23,19,43)',
                lineHeight: '1',
                padding: '0.35em',
                borderRadius:'10px',
                marginTop: '0%',
                display: 'block',
                position: 'absolute',
                left: '8%',
                top: '350px',
                textAlign: 'left',
                backgroundColor:'rgba(255,255,255,0.8)',}} >Find out who we are and how we work!</h2>

                <p className="ux" align="center" id="our-story"><a className="btnoutline" href="#our-story">⇩</a></p>
                <br />
                <br />
    <div className="container">
      <div className="columns">
        <div className="column">
          <div className="section" style={{marginLeft:'2.5%',width:'95%',marginTop:'-60px'}}>

          <div className="columns">
            <div className="column">
              <h3 style={{fontSize:'1.1em'}}>
              To say that we cut our professional teeth on creating content for e-commerce is to say nothing. Since 2013, we have been supporting the e-commerce industry by creating inspiring content. Starting from brand communication, through <Link className="dedlink" to="/offer/copywriting-pl/e-commerce-blogs/">texts for blogs</Link> and <Link className="dedlink" to="/offer/multilingual-copywriting/">multilingual product descriptions</Link> - we have already created hundreds of thousands of them. We supplement our grand writing skills and a clear message with our SEO knowledge and tools.
              <br /><br />
              In our everyday work we mainly use Polish, but we are familiar with many other languages. Native speakers from all over the world work for us. In addition to high linguistic competences, they have extensive marketing knowledge, which is cruicial in the content creating process.
              <br /><br />
              It is estimated that the Polish e-commerce market is worth as much as PLN 51 billion, and nearly 28 million Poles cannot imagine any other form of shopping than shopping online. No wonder that an average of 21 new online stores are created in our country. Every day! All this makes Poland the most dynamically developing e-commerce market in Europe.
              </h3>
              <br />
            </div>

            <div className="column">
            <h3 style={{fontSize:'1.1em'}}>
              That's why it's so important to stand out in this maze of online businesses. And well-thought-out content is key. This is where we come in!
              <br /><br />
              Despite many years of experience, we are still learning and looking for new solutions, and we say "no" to the well-established patterns and clichés!
              <br /><br />
              What languages do we work in? Due to the specificity of the Polish e-commerce, which is mainly geared towards neighbouring countries, we <strong>produce a lot of content in Czech, Slovak, Russian, German and Ukrainian. Apart from that we regularly write in Italian, French, Spanish, and also in Slavic and Scandinavian languages</strong>. However, we are not afraid of more exotic languages! Do you need product descriptions for the <strong>Turkish, Japanese or Arabic market</strong>? Give us a call and we will certainly help you even with the most exotic languages! </h3>
              <br />
            </div>

          </div>
          <br />
          <hr />

          </div>
        </div>

      </div>

    </div>






{/* Zdjęcie ze strzałkami */}
<div id="arrow-photo" style={{textAlign: 'center',height: 'max-content',width: '100%',justifyContent: 'center'}}>
        <Img fixed={data.file1.childImageSharp.fixed} alt="E-multicontent team - copywriting, translations, SEO, websites, e-commerce, marketplace"
        style={{
          position: 'absolute',
          width:'625px',
          zIndex: '-1',
          margin: 'auto'
        }} />



        <div style={{marginTop: '200px'}}>
        Kamil
        <br />
        <small>Office &amp; Content Assistant</small>
        <br />
        <a className="ded-link" style={{fontSize:'12px'}} href="mailto:kamil@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        kamil@e-multicontent.pl
        </a>
        </div>


        <div style={{marginTop: '320px'}}>
        Alicja
        <br />
        <small>PR &amp; Content Manager</small>
        <br />
        <a className="ded-link" style={{fontSize:'12px'}} href="mailto:alicja@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        alicja@e-multicontent.pl
        </a>
        </div>



        <div style={{marginTop: '350px'}}>
        Agnieszka
        <br />
        <small>CEO / Founder </small>
        <br />
        <a className="ded-link" style={{fontSize:'12px'}} href="mailto:agnieszka@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        agnieszka@e-multicontent.pl
        </a>
        </div>



        <div style={{marginTop: '300px',marginRight: '-40px'}}>
        Marta
        <br />
        <small> Content Team Leader </small>
        <br />
        <a className="ded-link" style={{fontSize:'12px'}} href="mailto:marta@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        marta@e-multicontent.pl
        </a>
        </div>

        <div style={{marginTop: '200px'}}>
        Joanna
        <br />
        <small>Translation Project Coordinator </small>
        <br />
        <a className="ded-link" style={{fontSize:'12px'}} href="mailto:joanna@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        joanna@e-multicontent.pl
        </a>
        </div>


</div>

    <div id="team-photo" className="container" style={{marginBottom:'50px',textAlign:'center'}}>
    <Img fluid={data.file2.childImageSharp.fluid}
    alt="E-multicontent team - copywriting, translations, SEO, websites, e-commerce, marketplace"
    style={{justifyContent:'center',display:'flex',flexWrap:'wrap',minHeight:'300px'}}
    imgStyle={{
      padding: "0",
      borderRadius: "10px",width:'100%',minHeight:'300px',
      border:'3px solid #f5f5f5',margin:'auto',marginBottom:'50px',
      backgroundColor: 'white',objectFit:'contain',objectPosition:'center'}} />

      <div className="columns" style={{textAlign:'center',marginTop:'25px'}}>
        <div className="column is-4">
        Kamil
        <br />
        <small>Office & Content Assistant</small>
        <br />
        <a className="btn" href="mailto:kamil@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        kamil@e-multicontent.pl
        </a>
        </div>

        <div className="column is-4">
        Alicja
        <br />
        <small>PR & Content Manager</small>
        <br />
        <a className="btn" href="mailto:alicja@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        alicja@e-multicontent.pl
        </a>
        </div>

        <div className="column is-4">
        Agnieszka
        <br />
        <small>CEO / Founder </small>
        <br />
        <a className="btn" href="mailto:agnieszka@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        agnieszka@e-multicontent.pl
        </a>
        </div>

      </div>
      <br />
      <div className="columns" style={{textAlign:'center'}}>
        <div className="column is-6">
        Marta
        <br />
        <small> Content Team Leader </small>
        <br />
        <a className="btn" href="mailto:marta@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        marta@e-multicontent.pl
        </a>
        </div>
        <div className="column is-6">
        Joanna
        <br />
        <small>Translation Project Coordinator </small>
        <br />
        <a className="btn" href="mailto:joanna@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        joanna@e-multicontent.pl
        </a>
        </div>
      </div>
    </div>
    <br />
    <div className="container">
    <div style={{margin:'0% 3%'}}>
    <h3 className="is-size-2" style={{fontWeight:'800'}}>
    <Typewriter
        onInit={(typewriter) => {
          typewriter.typeString("Meet our team and <b style='color:#36b3d2'>join us!</b>")
            .pauseFor(2500)
            .deleteAll()
            .start();
        }}
        options={{
          autoStart: true,
          loop: true,
        }}
      />
    </h3>
    <h4 style={{fontSize:'1.2em'}}>Here at e-multicontent, we currently cooperate with a group of over 45 native speakers who speak 35 different languages.<br /> If you want to join our team, please <Link to="/contact/" className="dedlink">contact us!</Link></h4>
    </div>
    </div>

    <div className="section">
      <div className="box sliderbox"  style={{ textAlign: 'center',padding:'50px',borderRadius:'20px', backgroundColor: '#36b3d2',color:' #f5f6f5',margin:'auto', marginBottom:'20px'}}  >
      <Slider />
      </div>
    </div>
    <div
      className="full-width-image lazy-background three"
      style={{
        backgroundColor: `#36b3d2`,
        backgroundPosition: `bottom left`,
        backgroundSize:'cover',
        backgroundRepeat:'repeat',
        width: '100%',
        marginBottom:'0px',
        marginTop: '0px',
        height: '100%',
        paddingTop: '50px',
        paddingBottom: '50px'

      }}
    >
    {/*         LICZNIKI         */}
    <div className="columns" style={{textAlign:'center',width:'80%'}}>

      <div style={{height:'20%',backgroundColor:'rgba(255,255,255,0.85)'}} className="column box">
        <br />
        <h2 style={{fontSize:'27px',color:'#29b1ff'}}><b id="t1">10</b></h2>
        <br />
        <h3 style={{color:'#222222'}}><b> years <br /> in the industry</b> </h3>
        <br />
      </div>

      <div style={{height:'20%',backgroundColor:'rgba(255,255,255,0.85)'}} className="column box">
        <br />
        <h2 style={{fontSize:'27px',color:'#29b1ff'}}><b id="t2">250000</b></h2>
        <br />
        <h3 style={{color:'#222222'}}><b> product<br /> descriptions</b> </h3>
        <br />
      </div>

      <div style={{height:'20%',backgroundColor:'rgba(255,255,255,0.85)'}} className="column box">
        <br />
        <h2 style={{fontSize:'27px',color:'#29b1ff'}}><b id="t3">95000</b></h2>
        <br />
        <h3 style={{color:'#222222'}}><b>texts<br /> written</b> </h3>
        <br />
      </div>


      <div style={{height:'20%',backgroundColor:'rgba(255,255,255,0.85)'}} className="column box">
        <br />
        <h2 style={{fontSize:'27px',color:'#29b1ff'}}><b id="t4">35</b></h2>
        <br />
        <h3 style={{color:'#222222'}}><b>different<br /> languages </b></h3>
        <br />
      </div>

      <br />


    </div>
    </div>


    <ConctactCall />
    {/*
    <div
      className="full-width-image-container lazy-background four"
      loading="lazy"
      style={{
        backgroundColor: `#36b3d2`,
        backgroundPosition: `bottom left`,
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat',
        height:'300px',
        width: '102%',
        marginBottom:'0px',
        marginTop: '0px'

      }}
    ></div>
    */}

</section>
  </Layout>
  </>
)}}

export const query = graphql`
  query File1{
    file1: file(relativePath: {regex: "/optimized/o-nas.png/"}) {
      childImageSharp {
        fixed(width: 625, height: 359) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
          srcWebp
        }
      }
    }
    file2: file(relativePath: {regex: "/optimized/o_nas.jpg/"}) {
      childImageSharp {
        fluid(fit: CONTAIN) {
        srcWebp
        srcSetWebp
        src
        srcSet
      }
      }
    }
    file3: file(relativePath: {regex: "/optimized/kontakt.jpg/"}) {
      childImageSharp {
        fluid(fit: CONTAIN) {
        srcWebp
        src
        srcSet
        srcSetWebp
      }
      }
    }
  }
`




//TIMERS

function countDown() {
//initial numbers
let x = 0;
let x1 = 0;
let x2 = 0;
let x3 = 0;


var a =  setInterval( function(){

    if (document.getElementById('t1')!=null){
        if (x<10){
          x++;
          document.getElementById('t1').innerHTML = x;
        } else if (x===10){
          clearInterval(a);
        }
        }else {
          clearInterval(a);
        }
      }, 1000);


var b =  setInterval( function(){

    if (document.getElementById('t2')!=null){
        if (x1<250000){
          x1+=50;
          document.getElementById('t2').innerHTML = x1;
        } else if (x1===250000){
          clearInterval(b);
        }
        }else {
          clearInterval(b);
        }
      }, 1);

var c =  setInterval( function(){

    if (document.getElementById('t3')!=null){
        if (x2<95000){
          x2+=25;
          document.getElementById('t3').innerHTML = x2;
        } else if (x2===95000){
          clearInterval(c);
        }
        }else {
          clearInterval(c);
        }
      }, 10);

var d =  setInterval( function(){

    if (document.getElementById('t4')!=null){
        if (x3<35){
          x3++;
          document.getElementById('t4').innerHTML = x3;
        } else if (x3===20){
          clearInterval(d);
        }
        }else {
          clearInterval(d);
        }
      }, 500);

}
